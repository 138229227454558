import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OptionsType, OptionTypeBase } from 'react-select/src/types';
import { FormikErrors, FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatchApp } from 'redux/rootSelectors';
import { TGetAuditDictionary } from 'domains/agency/types';
import { fetchAuditDictionaryList } from 'domains/agency/reducer';
import { useAuditDictionaryList } from 'domains/agency/model/selectors';
import i18n from '../../../i18n';
import { Settign, TExperimentalInfoField, TFormikSettigns } from "../types";
import {
  fetchListSRMethods,
  fetchTagsGetCompany,
  setEditPersonalCampaign,
  setSRMethodRequest,
} from '../model/actions';
import { TID } from '../../../types/general';
import {
  useCampaignInfo,
  useListSRMethodsInfo,
  useSelectorCampaignCompanyTagsInfo,
} from '../model/selectors';

type TFUseSRMethod = (p: ReturnType<typeof useCampaignInfo>['data']) => {
  onSaveSRMethod: () => void;
  SRMethod: string;
  listSRMethods: ReturnType<typeof useListSRMethodsInfo>['data'];
  onChangeSRMethod: (v: string | number) => void;
  setDefSRMethod: () => void;
  optTag: OptionsType<OptionTypeBase>;
  onChangeText: (v: string) => void;
  aggregationTag: OptionTypeBase | undefined;
  onChangeAggregation: (v: OptionTypeBase) => void;
};

type TFUseCampaignSettigns = (p: Settign) => {
  formik: FormikProps<TFormikSettigns>;
  isLoadingEdit: boolean;
  commissionAgencyData:TExperimentalInfoField,
  commissionSelfServiceData:TExperimentalInfoField,
  audiences: TGetAuditDictionary[];
  setCurrentAudit: React.Dispatch<React.SetStateAction<string>>;
};

export const useSRMethod: TFUseSRMethod = (campaign) => {
  const dispatch = useDispatchApp();

  const { id } = useParams<TID>();

  const { data: listSRMethods } = useListSRMethodsInfo();

  const [SRMethod, setSRMethod] = useState<string>('');
  const [defSRMethod, setDefSR] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [optTag, setOptTag] = useState<ReturnType<TFUseSRMethod>['optTag']>([]);
  const [filterTag, setfilterTag] = useState<string>('');
  const [aggregationTag, setAggregationTag] = useState<
    OptionTypeBase | undefined
  >(undefined);

  useEffect(() => {
    if (campaign === null) {
      return;
    }
    setTitle(campaign.title);
    setDefSR(campaign.method_recalculating_statistics);
    const aggregation_tag = campaign?.aggregation_tag;
    if (typeof aggregation_tag === 'string') {
      setAggregationTag({ label: aggregation_tag, value: aggregation_tag });
    }

    dispatch(fetchTagsGetCompany(campaign.xxhash));
  }, [campaign]);

  const { data: companyTags } = useSelectorCampaignCompanyTagsInfo();
  useEffect(() => {
    if (companyTags === null) return;
    if (!filterTag) return;
    if (filterTag.length < 3) {
      // eslint-disable-next-line consistent-return
      return setOptTag([]);
    }
    const filterData = companyTags.filter((item) =>
      item.toUpperCase().includes(filterTag.toUpperCase()),
    );
    setOptTag(filterData.map((i) => ({ label: i, value: i })));
  }, [companyTags, filterTag]);

  const onChangeText: ReturnType<TFUseSRMethod>['onChangeText'] = useCallback(
    (v) => {
      setfilterTag(v);
    },
    [setfilterTag],
  );
  const onChangeAggregation: ReturnType<TFUseSRMethod>['onChangeAggregation'] =
    useCallback(
      (v) => {
        setAggregationTag(v ?? { label: '', value: '' });
        setfilterTag(v?.value ?? '');
      },
      [setAggregationTag],
    );

  useEffect(() => {
    if (typeof id !== 'string') {
      return;
    }

    dispatch(fetchListSRMethods());
  }, [id]);
  useEffect(() => {
    if (typeof defSRMethod !== 'string') return;
    setSRMethod(defSRMethod);
  }, [defSRMethod]);

  const onChangeSRMethod: ReturnType<TFUseSRMethod>['onChangeSRMethod'] =
    useCallback(
      (v) => {
        if (typeof v !== 'string') {
          return null;
        }
        return setSRMethod(() => v);
      },
      [setSRMethod, SRMethod],
    );
  const setDefSRMethod: ReturnType<TFUseSRMethod>['setDefSRMethod'] =
    useCallback(() => {
      setSRMethod(defSRMethod);
    }, [setSRMethod, defSRMethod]);

  const onSaveSRMethod = useMemo(
    () => () => {
      if (
        defSRMethod !== SRMethod &&
        typeof SRMethod !== 'undefined' &&
        typeof id === 'string'
      ) {
        dispatch(setSRMethodRequest({ SRMethod, id, title }));
      }
    },
    [defSRMethod, SRMethod, title],
  );

  return {
    onSaveSRMethod,
    SRMethod,
    listSRMethods,
    onChangeSRMethod,
    setDefSRMethod,
    optTag,
    onChangeText,
    aggregationTag,
    onChangeAggregation,
  };
};

const commissionAgencyData:TExperimentalInfoField={
  default_value:0,
  id: 100,
  field: 'commission_agency',
  title: 'Комиссия агентства',
  type:'float',
  page: '',
  description: 'Для изменения CPM в DSP применяется двойная наценка. Скрытая комиссия агентства и наша комиссия за self-service',
  configFieldsList: [],
  max:9999.999999,
  min:0,
}
const commissionSelfServiceData:TExperimentalInfoField={
  default_value:0,
  id: 101,
  field: 'commission_self_service',
  title: 'Комиссия Self-Service',
  type:'float',
  page: '',
  description: 'Для изменения CPM в DSP применяется двойная наценка. Скрытая комиссия агентства и наша комиссия за self-service',
  configFieldsList: [],
  max:9999.999999,
  min:0,
}

export const useCampaignSettigns: TFUseCampaignSettigns = ({
  aggregationTag,
  SRMethod,
  configValuesIds,

  /* marginalityId, */
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const { data: campaign } = useCampaignInfo();
  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false);
  const { data: auditDictionaryList } = useAuditDictionaryList();
  const [audiences, setAudiences] = useState<TGetAuditDictionary[]>([]);
  const [currentAudit, setCurrentAudit] = useState<string>('');

  const getAuditList = (hash: string) => {
    dispatch(
      fetchAuditDictionaryList({
        partner_xxhash: hash,
      }),
    );
  };

  useEffect(() => {
    if (campaign) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getAuditList(campaign.bread_crumbs.Agency.xxhash);
    }
  }, [campaign]);

  useEffect(() => {
    if (auditDictionaryList) {
      setAudiences(auditDictionaryList);
    }
  }, [auditDictionaryList]);

  const getAuditIdByTitle = (
    audiencesArr: TGetAuditDictionary[],
    audTitle: string,
  ) => {
    const foundAudience = audiencesArr.find(
      (audience) => audience.title === audTitle,
    );
    return foundAudience ? foundAudience.id : 0;
  };

  type TFValidate = (values: TFormikSettigns) => FormikErrors<TFormikSettigns & Record<string, string>>;

  const validateFormCampaign: TFValidate = (values) => {
    const errors: FormikErrors<TFormikSettigns & Record<string, string>> = {};

    if(SRMethod === 'SELF_SERVICE_MARKUP' && values?.config_values) {
      [commissionAgencyData,commissionSelfServiceData].forEach(item => {
        const fieldValue = values.config_values?.[item.id]||0

        if (+fieldValue > (item.max||Infinity)) {
          errors[item.id] = `${i18n.t('errors.limitsym')}`
        }

      })

      return errors
    }
    if(SRMethod !== 'MAX_FIX_CPC_MARGINALITY') return errors

    configValuesIds.forEach((id) => {
      const fieldValue = values?.config_values && values.config_values[id]
/*       if (id === marginalityId) {
        if (fieldValue && +fieldValue > 100) {
          errors[id] = `${i18n.t('errors.percent_limit')}`
        }
        return
      } */
      if (fieldValue && +fieldValue > 2147483647) {
        errors[id] = `${i18n.t('errors.limitsym')}`
      }
    })

    if (values.hidden_commissions && values.hidden_commissions > 2147483647) {
      errors.hidden_commissions = `${i18n.t('errors.limitsym')}`
    }

    return errors;
  };

  const formik = useFormik<TFormikSettigns>({
    onSubmit: (values) => {
      const params = {
        ...values,
        dashboard_data: values.dashboard_data,
        external_ids: values.external_ids,
        additional_metrics: values.additional_metrics,
        aggregation_tag: aggregationTag?.value,
        statistic_coefficient: values.statistic_coefficient?.replace(/ /g, '\t'),
        check_ERID: values.check_ERID,
        audit_dictionary_id: getAuditIdByTitle(audiences, currentAudit),
        method_recalculating_statistics:
          SRMethod || (campaign && campaign.method_recalculating_statistics),
        reports: {
          ...values.reports,
          items: values.reports.items.map((e) => ({
            type: e.type,
            is_enabled: e.is_enabled,
          })),
        },
        fields:{}
      };

      if (SRMethod !== 'COEFFICIENTS') {
        if ('statistic_coefficient' in params) {
          delete params.statistic_coefficient;
        }
      }
      if (SRMethod === 'MAX_FIX_CPC_MARGINALITY') {
        delete params.additional_metrics
      }
      if (SRMethod !== 'MAX_FIX_CPC_MARGINALITY') {
        delete params.config_values
        delete params.hidden_commissions
      }

      if (SRMethod === 'SELF_SERVICE_MARKUP') {
        params.fields = {
          commission_agency:values.config_values?.[commissionAgencyData.id]||0,
          commission_self_service:values.config_values?.[commissionSelfServiceData.id]||0
        }
      }
      delete params.config_values?.[commissionAgencyData.id];
      delete params.config_values?.[commissionSelfServiceData.id];


      const keys = Object.keys(params);

      keys.forEach((e) => {
        if (!params[e] && !typeof params[e]) {
          delete params[e];
        }
      });

      if (campaign) {
        dispatch(
          setEditPersonalCampaign({
            xxhash: campaign.xxhash,
            ...params,
            blockTitle: t(
              'campaigns_page.campaign_settings.black_menu.Settings',
            ),
            callback: {
              setIsLoading: setIsLoadingEdit,
            },
          }),
        );
      }
    },
    initialValues: {
      dashboard_data: '',
      additional_metrics: '',
      extended_report: '',
      external_ids: '',
      hidden_commissions: 0,
      reports: { use_inherit_settings: false, items: [] },
      statistic_coefficient: '',
      check_ERID: false,
      hidden_statistic: false,
      config_values: {},
    },
    validate: validateFormCampaign,
  });

  useEffect(() => {
    if (campaign) {
      formik.setValues({
        dashboard_data: campaign.dashboard_data,
        additional_metrics: campaign.additional_metrics,
        extended_report: campaign.extended_report,
        external_ids: campaign.external_ids,
        reports: campaign.reports,
        statistic_coefficient: campaign.statistic_coefficient,
        hidden_statistic: campaign.hidden_statistic,
        check_ERID: !!campaign.check_ERID.CAMPAIGN,
        hidden_commissions: campaign.hidden_commissions,
        config_values: {},
      });
    }
  }, [campaign]);

  useEffect(() => {
    if(SRMethod === 'SELF_SERVICE_MARKUP')  {
      formik.validateForm()
      return
    }
    if(SRMethod === 'MAX_FIX_CPC_MARGINALITY')  {
      formik.validateForm()
      return
    }
    formik.setErrors({})
  }, [SRMethod])


  useEffect(() => {
    if (campaign) {
      const currentConfigValues = {}
      configValuesIds.forEach(id => {
        const value = campaign.config_values ? campaign?.config_values[id] : ''
        currentConfigValues[id] = value
      });

      currentConfigValues[commissionAgencyData.id]=campaign.commission_agency
      currentConfigValues[commissionSelfServiceData.id]=campaign.commission_self_service

      formik.setFieldValue('config_values', currentConfigValues);


    }
  }, [campaign, configValuesIds]);

  return {
    formik,
    isLoadingEdit,
    commissionAgencyData,
    commissionSelfServiceData,
    audiences,
    setCurrentAudit,
  };
};
