import { OptionTypeBase } from 'react-select/src/types';
import { ReactText } from 'react';
import { FormikProps } from 'formik';
import { TERIDObject } from 'domains/client/types';
import { GName } from 'domains/user/types';
import { DateType, SelectField, TDates } from '../../../types/general';
import { TItems, TStatisticsKey } from '../../target/model/types';

export type TchartItems = {
  name: string;
  isOff: boolean;
};

export type TSeries = {
  color: string | undefined;
  areaOpacity: number;
  targetAxisIndex: number;
}[];

export type ListSRMethods = Array<[string, string]>;

export type TDH = {
  /** Общий бюджет */
  total: number;
  /** Дневной бюджет  */
  day: number;
  /** Часовой бюджет кампани */
  hour: number;
};

export type Tlimits = {
  /** Бюджет кампании */
  budget: TDH;
  /** Бюджет кампании на 1 пользователя */
  budget_per_user: TDH;
  /** Лимит показов  */
  show: TDH;
  /** Лимит показов на 1 пользователя */
  show_per_user: TDH;
  /** Лимит переходов */
  click: TDH;
  /** Лимит переходов на 1 пользователя */
  click_per_user: TDH;
  /** Лимиты для owner */
  budget_raw: TDH;
  use_adriver_frequency_cap: boolean;
};

export type Ttarget = {
  /** Режим инвертирования.
   * При false (по-умолчанию) работает в режиме whitelist,
   * true - blacklist */
  settings: {
    is_invert_mode: boolean;
    /** Индикатор - есть ли выбранные значения */
    has_active_items: boolean;
    use_inherit_settings: boolean;
    /** Объект значений */
  }
  items: Record<string, TItems>;
};

export type TReportItem = {
  /** Тип отчета */
  type:
    | 'day'
    | 'day_techfee'
    | 'yandex'
    | 'nmi-integration-src'
    | 'integration'
    | 'socdem'
    | 'geo_city'
    | 'shownum'
    | 'extended';
  /** Включен? */
  is_enabled: boolean;
};
type TReport = {
  /** Использовать ли настройки по-умолчанию?  */
  use_inherit_settings: boolean;
  /** Список настроек отчетов */
  items: TReportItem[];
};

export type TContainer = {
  /** Идентификаторы контейнера */
  cid: string;
  /** сохранение */
  save_strk_to_cookie: 0 | 1;
};

export type TStatusCampaign =
  | 'STOPPED'
  | 'LAUNCHED'
  | 'DELETED'
  | 'ARCHIVE'
  | '';

export type groupData = {
  id: number;
  title: string;
  key: string;
  limits: Record<string, Record<string, number>>;
};

export type RGetPersonal = {
  /** ID кампании */
  xxhash: string;
  /** Внутренний текстовый ID (например, tdt4#test-1.1) */
  internal_id?: string;
  /* Время жизни клика */
  lead_wait_time: number;
  /** Заголовок агенстав */
  partner_xxhash: string;
  /** Заголовок кампании */
  title: string;
  /** Список договоров */
  contracts: SelectField[];
  /** Изначальный договор */
  contract_uuid: string | null;
  /** Статус кампании. */
  status: TStatusCampaign;
  /** Имя рекламодателя */
  advertiser_name: string;
  /** Лимиты кампании */
  limits: Tlimits;
  /** Цель оптимизации, строка в значении cpc, cpm, cpa */
  pay_type: string;
  /** Цена в рублях, до 3 знаков после запятой */
  pay_price: number | null;
  /** Дата старта кампании YYYY-MM-DD */
  date_start: DateType;
  /** Дата редактирования кампании YYYY-MM-DD */
  date_edit: DateType;
  /** Дата завершения кампании YYYY-MM-DD */
  date_end: DateType;
  /** Расширенные данные (например, значения GA-сессий для CPA-кампаний)
   выводится только пользователям группы owner */
  additional_metrics?: string;
  /** Идентификаторы данных на внешних источниках
   выводится только пользователям группы owner */
  external_ids?: string;
  /** Настройка проверки ERID */
  check_ERID: TERIDObject;
  /** идентификаторы dashboard */
  dashboard_data?: string;
  /** Настройки видимости отчетов */
  reports: TReport;
  /** Данные для расширенного отчета
   выводится только пользователям группы owner */
  extended_report?: string;
  /** метод калькуляции статистики */
  method_recalculating_statistics: string;
  /** Данные аудитории ML */
  audit_dictionary: {
    id: number;
    status: string;
    title: string;
  };
  /** id аудитории ML */
  audit_dictionary_id?: number;
  /** вида
   01.11.2019 339 */
  statistic_coefficient: string;
  /** только для raw_stat */
  aggregation_tag?: string;
  /** Контайнер */
  container: TContainer;
  /** дата создания */
  date_create: DateType;
  /** включена ли оптимизация */
  cpa_optimizations: boolean;
  /** список свойств оптимизации */
  advanced_optimizations: number[];
  /** Сокрытие реальной статистики */
  hidden_statistic: boolean;
  group: groupData | null;
  config_values?: Record<string, string>;
  /* Иконка кампании */
  icon: string | null;
  pay_optimization_field_id?: number | null;
  offer_id: number | null;
  hidden_commissions?: number;
  commission_agency?: number;
  commission_self_service?: number;
  statistic_type: string;
};

export type RGetPersonalTransformForForm = Omit<
  RGetPersonal,
  'date_end' | 'date_start' | 'advanced_optimizations'
> & {
  /** Дата старта кампании YYYY-MM-DD */
  date_start: Date | string;
  /** Дата завершения кампании YYYY-MM-DD */
  date_end: Date | string;
  advanced_optimizations: Record<string, number>;
};

export type TCampaignForm = Pick<
  RGetPersonalTransformForForm,
  | 'title'
  | 'contract_uuid'
  | 'advertiser_name'
  | 'status'
  | 'partner_xxhash'
  | 'date_start'
  | 'date_end'
  | 'pay_price'
  | 'pay_type'
  | 'cpa_optimizations'
  | 'advanced_optimizations'
  | 'container'
  | 'limits'
  | 'pay_optimization_field_id'
  | 'offer_id'
> & {
  config_values?: Record<string, string | number | boolean>;
};

export type CampaignGeneralComponentProps = {
  formik: FormikProps<TCampaignForm>;
  autoSubmit?: boolean;
  savedField?: Record<string, unknown>;
  handlerInput?: () => void;
  currency: string;
  setField: (
    name: string,
    value: string | number | undefined | boolean,
  ) => void;
  handlerFormikSubmit?: () => void;
};

export type PSetEditPersonalCampaign = Partial<
  Pick<
    RGetPersonal,
    | 'partner_xxhash'
    | 'advertiser_name'
    | 'lead_wait_time'
    | 'status'
    | 'title'
    | 'container'
    | 'limits'
    | 'pay_price'
    | 'pay_type'
    | 'cpa_optimizations'
    | 'advanced_optimizations'
  > & {
    /** Дата старта кампании YYYY-MM-DD */
    date_start: string;
    /** Дата завершения кампании YYYY-MM-DD */
    date_end: string;
    translation: boolean;
    blockTitle: string;
    blockGetQuery: boolean;
    callback: {
      setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    };
  }
> & {
  xxhash: string;
};

export type StatisticsStructure = {
  bid_statistic: number;
  click: number;
  cpc: number;
  cpm: number;
  ctr: number;
  show: number;
  spent: number;
};
export type RFrontStatistics = {
  show: string[][];
  clicks: string[][];
};

export type RCampaignStatistics = {
  /** Идентификатор кампании */
  campaign_id: string;
  /** Идентификатор Агентсва */
  agency_id: string;
  /** Дата по кликам и показам */
  items: Record<string, StatisticsStructure>;
};

export type IParamsEditCampaign = {
  partner_xxhash?: string;
  advertiser_name?: string;
  title?: string;
  status?: 'STOPPED' | 'LAUNCHED' | 'DELETED' | 'ARCHIVE';
  blockTitle?: string;
  tags?: string[];
};

export type PuseTags = {
  campaign: RGetPersonal;
  pastCampaign: RGetPersonal[];
  onEdit: (params: IParamsEditCampaign) => void;
};

export type TCampaignEditAdvanced = {
  cpa_optimizations: RGetPersonal['cpa_optimizations'];
  advanced_optimizations: Record<string, number>;
};

export type ParamsEditCampaign = {
  xxhash: RGetPersonal['xxhash'];
  title?: RGetPersonal['title'];
  partner_xxhash?: RGetPersonal['xxhash'];
  status?: RGetPersonal['status'];
  limits?: RGetPersonal['limits'];
  pay_type?: RGetPersonal['pay_type'];
  pay_price?: RGetPersonal['pay_price'];
  date_start?: RGetPersonal['date_start']['date'];
  date_end?: RGetPersonal['date_end']['date'];
  date_create?: string;
  date_edit?: string;
  reports?: RGetPersonal['reports'];
  external_ids?: RGetPersonal['external_ids'];
  extended_report?: RGetPersonal['extended_report'];
  additional_metrics?: RGetPersonal['additional_metrics'];
  method_recalculating_statistics?:
    | RGetPersonal['method_recalculating_statistics']
    | null;
  config_values?: Record<string, string | number | boolean>;
  icon?: string;
};

export type PreTargeting = {
  key: string;
  checked: boolean;
  title: string;
  order: number;
};

export type RPreTargeting = {
  currentValue?: RPreTargeting;
  pretargeting?: PreTargeting[];
  brand_safety?: {
    '0'?: PreTargeting;
    disallow_group?: PreTargeting[];
  };
};
export type PSetPreTargeting = {
  xxhash: string;
  data: RPreTargeting;
};
export type PSetPreTargetingWorker = PSetPreTargeting;

export type PCampaignReport = {
  xxhash: string;
  type: TReportItem['type'];
  mode?: 'return' | 'tsv' | 'csv' | 'xlx' | 'xlsx' | 'xls';
  period: TDates['period'];
  filters?: Record<string, string>;
  subtype?: 'rowstat';
};

type RReportStatistics = Record<string, number>;
export type StatisticsType = number | string;

export type RCampaignReport = {
  items: Record<string, RReportStatistics>;
  total: RReportStatistics;
  other?: RReportStatistics;
};
export type RCampaignReportIntegration = {
  date: Record<string, StatisticsType>[];
  total: RReportStatistics;
  utm: Record<string, StatisticsType>[];
};

export type TReportData = {
  statistics: Record<string, StatisticsType>[];
  statisticsIntegration?: {
    date: TReportData['statistics'];
    utm: TReportData['statistics'];
  };
  total: Record<string, number>;
  other?: Record<string, number>;
  isDate: boolean;
};

export type RReportDownload = {
  report_url: string;
};
export type RStatisticsField = {
  id: number;
  title: string;
  key: TStatisticsKey;
  order: number;
  default: boolean;
  checked: boolean;
};
export type TStatisticsFields = RStatisticsField[];

export type PSetStatisticsFields = {
  xxhash: string;
  field_list: number[];
};
export type PSagaSetStatisticsFields = PSetStatisticsFields & {
  types: string[];
};

export type TFieldStatistics = {
  key: TStatisticsKey;
  title: string;
  isLengthTitle: number;
  order?: number;
};

/** Типы для отчетов */
export type TItem = { [K: string]: string | number | Date | TItem[] };
export type TItemIndx = {
  _ID: string;
  [K: string]: string | number | Date | TItemIndx[];
};
export type ChartDataItem = { [K: string]: string | number | Date };

export type GeneralPParent = {
  statistics: TItem[];
  chartData?: ChartDataItem[];
  namingItem: TNamingItem;
  defchartItems?: ReadonlyArray<string>;
};
export type GeneralP = {
  fixedRowT?: GeneralPParent['statistics'];
  filtredChartData?: any;
  onGetReportFilters?: any;
  requestParams?: any;
  isUtmTags?: boolean;
};

export const tableEvents = ['ASCENDING', 'DESCENDING', 'DEL_SORT'];
export const chartEvents = ['ADD_CHART', 'DEL_CHART'];

export type TableEvents = typeof tableEvents[number];
export type ChartEvents = typeof chartEvents[number];

export type SortEvent = {
  item: string;
  event: TableEvents;
};

export type TNamingItem = Map<
  string,
  {
    title: string;
    color?: string;
    isIndex?: boolean;
    notSort?: boolean;
    formatCell?: (item: TItemIndx) => string;
    noChart?: boolean;
    noRender?: boolean;
  }
>;

export type TOnSetFilterTable = (p: (ReactText | string)[]) => void;

export type TItemSFilter = {
  key: string | ReactText;
  checked: boolean;
};

export interface IonClickHeaderM {
  item: string;
  setSortEvent: (v: SortEvent) => void;
  chartItems: ReadonlyArray<TchartItems>;
  setChartItems: (v: ReadonlyArray<TchartItems>) => void;
  onSetFilterTable: TOnSetFilterTable;
  isUtmTags?: boolean;
}

export type TChartItems = {
  name: string;
  isOff: boolean;
};

export type TChartDate = Record<string, StatisticsType>;

export type TFormikSettigns = {
  hidden_statistic: RGetPersonal['hidden_statistic'];
  additional_metrics: RGetPersonal['additional_metrics'];
  statistic_coefficient?: RGetPersonal['statistic_coefficient'];
  external_ids: RGetPersonal['external_ids'];
  dashboard_data: RGetPersonal['dashboard_data'];
  extended_report: RGetPersonal['extended_report'];
  reports: RGetPersonal['reports'];
  check_ERID: boolean;
  config_values: RGetPersonal['config_values'];
  hidden_commissions: RGetPersonal['hidden_commissions'];
};

export type Settign = {
  SRMethod: string;
  aggregationTag: OptionTypeBase | undefined;
  configValuesIds: number[];
  marginalityId?: number;
};

export type TExperimentalInfo = {
  [key: string]: TExperimentalInfoField;
};

export type listItem = {
  id: number;
  title: string;
  field: string;
};

export type TExperimentalInfoField = {
  default_value?: string | number | boolean;
  id: number;
  field: string;
  title: string;
  type: string;
  page: string;
  description: string;
  configFieldsList: listItem[];
  max?:number;
  min:number
};

export type PExperimental = {
  value: string | number | boolean;
  id: number;
  xxhash?: string;
};

export type PayloadExperimental = PExperimental & {
  callbacks: {
    onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

export interface RUseFieldValue {
  formik: FormikProps<PExperimental>;
  onKeyHandler: (keyboardEvent: React.KeyboardEvent) => void;
  setField: (name: string, val: string | number | undefined) => void;
  success: boolean;
}

type TGroupItem = {
  id: number,
  title: GName,
}

export type TOptimizationItem = {
  id: number,
  title: string,
  statistic: string,
  td: string,
  default: true,
  type: string,
  calculated: true,
  formula: string,
  order: number,
  optimization: true,
  groups: TGroupItem[]
}

export type TOptimizationList = TOptimizationItem[]
